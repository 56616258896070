import styled from "@emotion/styled";
import { Field, Form, Formik, FormikActions } from "formik";
import React, { useCallback, useState } from "react";
import Helmet from "react-helmet";
import Recaptcha from "react-recaptcha";
import * as Yup from "yup";
import { Body } from "../../components/Body";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";

interface ContactFormValues {
  name: string;
  email: string;
  phone: string;
  enquiry: string;
  recaptcha: string;
}

const contactFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  phone: Yup.string()
    .min(2, "Too Short!")
    .max(20, "Too Long!")
    .required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  enquiry: Yup.string()
    .min(2, "Too Short!")
    .max(400, "Too Long!")
    .required("Required"),
  recaptcha: Yup.string().required("Required"),
});

const ContactPage = () => {
  const [success, setSuccess] = useState(false);

  const handleSubmit = useCallback(
    async (
      values: ContactFormValues,
      actions: FormikActions<ContactFormValues>
    ) => {
      actions.setSubmitting(true);

      const response = await fetch("/api/contact", {
        method: "post",
        body: JSON.stringify(values),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      const data = await response.json();
      if (data && data.success) {
        setSuccess(true);
      }
      actions.setSubmitting(false);
    },
    []
  );

  return (
    <>
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js" async defer />
      </Helmet>
      <Header />
      <Body>
        <h2>Contact</h2>
        {success ? (
          <p>
            Thanks for sending your message! We'll get back to you shortly.{" "}
          </p>
        ) : (
          <Formik<ContactFormValues>
            initialValues={{
              name: "",
              email: "",
              phone: "",
              enquiry: "",
              recaptcha: "",
            }}
            validationSchema={contactFormSchema}
            onSubmit={handleSubmit}
            render={({ setFieldValue }) => (
              <Form>
                <Field
                  name="name"
                  render={({ field, form }) => (
                    <p>
                      <Label>Name:*</Label>
                      <Input type="text" {...field} />
                      <Error>
                        {form.touched.name &&
                          form.errors.name &&
                          form.errors.name}
                      </Error>
                    </p>
                  )}
                />

                <Field
                  name="phone"
                  render={({ field, form }) => (
                    <p>
                      <Label>Phone:*</Label>
                      <Input type="tel" {...field} />
                      <Error>
                        {form.touched.phone &&
                          form.errors.phone &&
                          form.errors.phone}
                      </Error>
                    </p>
                  )}
                />

                <Field
                  name="email"
                  render={({ field, form }) => (
                    <p>
                      <Label>Email:*</Label>
                      <Input type="email" {...field} />
                      <Error>
                        {form.touched.email &&
                          form.errors.email &&
                          form.errors.email}
                      </Error>
                    </p>
                  )}
                />

                <Field
                  name="enquiry"
                  render={({ field, form }) => (
                    <p>
                      <Label>Enquiry:*</Label>
                      <Textarea rows={10} {...field} />
                      <Error>
                        {form.touched.enquiry &&
                          form.errors.enquiry &&
                          form.errors.enquiry}
                      </Error>
                    </p>
                  )}
                />

                <Field
                  name="recaptcha"
                  render={({ form }) => (
                    <p>
                      <Recaptcha
                        sitekey="6LcZtqYUAAAAALhjI_jFF_U9gjFyYuJNvSaOFMhK"
                        render="explicit"
                        onloadCallback={() => {}}
                        verifyCallback={response => {
                          setFieldValue("recaptcha", response);
                        }}
                      />
                      <Error>
                        {form.touched.recaptcha &&
                          form.errors.recaptcha &&
                          form.errors.recaptcha}
                      </Error>
                    </p>
                  )}
                />

                <Button type="submit">
                  <ButtonInner>Submit</ButtonInner>
                </Button>
              </Form>
            )}
          />
        )}
      </Body>
      <Footer />
    </>
  );
};

const Button = styled.button`
  background: rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 4px;
  font-family: "Josefin Sans", sans-serif;
  font-size: 16px;
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

const ButtonInner = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
`;

const Input = styled.input`
  border-radius: 4px;
  padding: 4px;
  width: 100%;
  max-width: 240px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const Textarea = styled.textarea`
  border-radius: 4px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const Error = styled.span`
  display: block;
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;

export default ContactPage;
